import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import { followMember } from '@wix/bi-logger-groups/v2';
import type { IGroupMember, IMember } from '@wix/social-groups-serverless/dist/members/types';

import { useController } from 'common/context/controller';
import { selectCurrentUser, selectFollowStatus } from 'store/selectors';

import { Button } from 'wui/Button';
import {
  OverridableComponent,
  OverridableComponentProps,
} from 'wui/OverridableComponent';

import { PrivateProfileHint } from '../PrivateProfileHint';

interface IProps extends React.ComponentProps<typeof Button> {
  // used for BI
  groupId?: string;
  profile: IMember | IGroupMember;
  feedItemId?: string; // TODO need to refactor store -> remove user logic from feed/slice.ts
}

export const FollowButton = React.forwardRef(
  // eslint-disable-next-line react/display-name
  (props: OverridableComponentProps<IProps, typeof Button>, ref) => {
    const {
      as: Component = Button,
      profile,
      groupId,
      feedItemId,
      ...rest
    } = props;

    const bi = useBi();
    const { t } = useTranslation();
    const { members$, application$, verticals$ } = useController();
    const user = useSelector(selectCurrentUser);
    const status = useSelector(selectFollowStatus(profile.memberId as string));

    const isCurrentUser = user.siteMemberId === profile.memberId;
    const isFollowing = profile.connections?.followedByMember;

    if (isCurrentUser) {
      return (
        <Component
          outlined
          ref={ref}
          onClick={handleCurrentUserClick}
          disabled={!verticals$.hasMembersInstalled}
          {...rest}
        >
          {t('groups-web.my-profile')}
        </Component>
      );
    }

    return (
      <PrivateProfileHint disabled={!profile.isPrivate}>
        <Component
          ref={ref}
          onClick={handleClick}
          outlined={isFollowing}
          loading={status.loading}
          disabled={status.loading || !profile.memberId}
          {...rest}
        >
          {isFollowing ? t('groups-web.following') : t('groups-web.follow')}
        </Component>
      </PrivateProfileHint>
    );

    function handleCurrentUserClick() {
      members$.navigateToMember?.({
        memberSlug: user.slug,
        memberId: user.siteMemberId,
      });
    }

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
      event.stopPropagation();

      if (!user.loggedIn) {
        application$.promptLogin();
        return;
      }

      isFollowing
        ? members$.unfollow(profile.memberId as string, feedItemId)
        : members$.follow(profile.memberId as string, feedItemId);

      bi.report(
        followMember({
          group_id: groupId,
          site_member_id: profile.memberId,
          origin: 'discussion_tab_link_clk',
          action: isFollowing ? 'unfollow' : 'follow',
        }),
      );
    }
  },
) as OverridableComponent<IProps, typeof Button>;

FollowButton.displayName = 'FollowButton';
