import React from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from '@wix/tpa-settings/react';
import { SwitchTransition } from 'react-transition-group';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams, useRouter } from '@wix/tpa-router/react';

import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import { selectGroups } from 'store/selectors';

import { EGroupPartition } from '@wix/social-groups-serverless/dist/groups/types';

import { Box } from 'wui/Box';
import { Fade } from 'wui/Fade';

import { useController } from 'common/context/controller';
import { useBiParams } from 'common/hooks/useBiParams';

import { GroupList } from '../GroupList';
import { Tabs } from '../../../Tabs';
import { EmptyGroupList } from '../../../EmptyGroupList';

import stylesParams from 'Groups/stylesParams';

const { groupCardListTopBottomSpacing } = stylesParams;

export function Groups() {
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const styles = useStyles();
  const biParams = useBiParams();

  const router = useRouter();
  const { params } = useCurrentStateAndParams();
  const state = useSelector(selectGroups(params.partition));

  const { groups$ } = useController();

  return (
    <Box
      as="section"
      data-hook="groups"
      direction="vertical"
      padding={isMobile ? 'SP5' : 'SP0'}
      gap={styles.get(groupCardListTopBottomSpacing)}
    >
      <Tabs />
      <SwitchTransition>
        <Fade key={params.partition} timeout={500}>
          <GroupList
            groups={state.groups}
            meta={state.meta}
            status={state.status}
            onRetry={handleRetry}
            onLoadMore={handleLoadMore}
            emptyState={
              <EmptyGroupList
                filters={params}
                onReset={handleReset}
                data-hook="empty-state"
                variant="page-borderless"
                partition={params.partition}
              />
            }
          />
        </Fade>
      </SwitchTransition>
    </Box>
  );

  function handleReset() {
    router.go(
      '.',
      {
        title: '',
        disableScrollToTop: true,
        partition: EGroupPartition.ALL,
      },
      { location: 'replace' },
    );
  }

  function handleLoadMore() {
    bi.report(
      groupsLivesiteClick({
        screen_name: biParams.groupsScreenWithTab(),
        button_name: 'show_more_groups',
      }),
    );
    groups$.query({
      limit: 6,
      offset: state.groups.length,
      namespace: params.partition,
      partition: params.partition,
      ...params,
    });
  }

  function handleRetry() {
    groups$.query({
      limit: 6,
      namespace: params.partition,
      partition: params.partition,
      ...params,
    });
  }
}

Groups.displayName = 'Groups';
