import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';

import {
  groupsSearchSearchResultClickSrc111Evid525,
  paidPostSubscribeClick,
} from '@wix/bi-logger-groups-data/v2';

import type { IFeedItem } from '@wix/social-groups-serverless/dist/feed/types';
import { selectGroupSlugById } from 'store/selectors';

import { useController } from 'common/context/controller';
import { COMPONENT_ID } from 'common/utils';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { TextButton } from 'wui/TextButton';
import { Typography } from 'wui/Typography';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { Card, type ICardProps } from 'wui/Card';
import { ChevronRight as ChevronRightIcon } from '@wix/wix-ui-icons-common/on-stage';

import { GROUPS_APP_DEFINITION_ID } from '../../../../constants';

import { Link } from '../../Link';
import { FeedItemHeader } from '../../FeedItem/FeedItemHeader';
import { FeedItemSearchPreview } from './FeedItemSearchPreview';

import { useHighLightColor, useHighlight, truncate } from './helpers';
import classes from './styles.scss';

interface IProps {
  item: IFeedItem;
  promote?: boolean;
  origin: string;
  query: string;
}

export function FeedItemSearchResult(props: IProps & Partial<ICardProps>) {
  const { item, className, promote, origin, query, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const color = useHighLightColor();
  const { isMobile } = useEnvironment();

  const { pricingPlans$ } = useController();

  const title = item.entity?.title || item.preview?.title || '';

  const titleHighlight = item.highlights?.find(
    (value) => value?.field === 'entity.title',
  )?.highlightedText;

  const contentHighlight = useHighlight(
    item.highlights?.find((value) => value?.field === 'rawText')
      ?.highlightedText,
  );

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const hasMoreResults = contentHighlight.total > contentHighlight.count;

  const slug = useSelector(selectGroupSlugById(groupId));

  return (
    <Card
      {...rest}
      className={cls(classes.root, className)}
      style={{ '--highlight-color': color.value } as React.CSSProperties}
    >
      <FeedItemHeader
        item={item}
        padding="0 SP6"
        promote={promote}
        action={
          <Show if={!isMobile && hasMoreResults}>
            <Typography secondary variant="p2-14">
              {t('groups-web.search.feed-item.more-results')}
            </Typography>
          </Show>
        }
      />

      <CardContent as="div" padding="0 SP6" gap="SP5">
        <Show if={!!title}>
          <Typography
            variant="p2-20"
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: titleHighlight || title }}
          />
        </Show>

        <Show if={item.permissions?.canViewFullPost}>
          <Typography
            className={classes.content}
            dangerouslySetInnerHTML={{
              __html:
                contentHighlight.preview ||
                truncate(item.entity.rawText || '', 60),
            }}
          />
        </Show>

        <Hide if={item.permissions?.canViewFullPost}>
          <FeedItemSearchPreview
            item={item}
            onSubscribe={navigateToPackagePicker}
          />
        </Hide>
      </CardContent>

      <CardActions padding="0 SP6">
        <Box
          gap="SP3"
          width="100%"
          align="space-between"
          direction={isMobile && hasMoreResults ? 'vertical' : 'horizontal'}
        >
          <Box gap="SP3">
            <Typography>
              {t('groups-web.discussion.feed.reactions.total_icu', {
                count: item.reactions?.total ?? 0,
              })}
            </Typography>
            <Typography>
              {t('groups-web.discussion.feed.total-comments_icu', {
                count: item.latestComments?.total ?? 0,
              })}
            </Typography>
          </Box>

          <Box align="space-between" verticalAlign="middle">
            <Show if={isMobile && hasMoreResults}>
              <Typography secondary variant="p2-14">
                {t('groups-web.search.feed-item.more-results')}
              </Typography>
            </Show>

            <Show if={!item.permissions?.canViewFullPost}>
              <TextButton
                variant="link"
                onClick={navigateToPackagePicker}
                suffixIcon={<ChevronRightIcon />}
              >
                {t('groups-web.search.feed-item.subscribe')}
              </TextButton>
            </Show>

            <Show if={item.permissions?.canViewFullPost}>
              <Link
                variant="link"
                state="group.discussion.post"
                params={{ slug, feedItemId }}
                suffixIcon={<ChevronRightIcon />}
                bi={groupsSearchSearchResultClickSrc111Evid525({
                  origin,
                  groupId,
                  tabName: 'posts',
                  inputText: query,
                })}
              >
                {t('groups-web.search.feed-item.view-post')}
              </Link>
            </Show>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );

  function navigateToPackagePicker() {
    bi.report(
      paidPostSubscribeClick({
        group_id: groupId,
        post_id: feedItemId,
        post_details: JSON.stringify({
          type: 'search_preview',
          photo: item.mediaData?.imageCount,
          video: item.mediaData?.videoCount,
          document: item.mediaData?.fileCount,
        }),
      }),
    );

    pricingPlans$.navigateToPackagePicker({
      planIds: item.accessRestriction?.paidPlansOptions?.planIds,
      biOptions: {
        referralInfo: 'groups_search_results',
        referralId: feedItemId,
      },
      checkout: {
        successStatus: {
          content: {
            title: t('groups-web.restriction.paid-post.success.title'),
            message: t('groups-web.restriction.paid-post.success.body'),
            cta: t('groups-web.restriction.paid-post.success.cta'),
          },
          navigation: {
            type: 'section',
            options: {
              appDefinitionId: GROUPS_APP_DEFINITION_ID,
              sectionId: COMPONENT_ID.GROUP,
              state: `${slug}/discussion/${feedItemId}`,
            },
          },
        },
      },
    });
  }
}

FeedItemSearchResult.displayName = 'FeedItemSearchResult';
